
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');
body {
  scroll-behavior: smooth;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.scrollContainer {
  position: absolute;
  z-index: 99;
  top: 0;
  right: 50px;
  height: 100%;
  /* transform: rotate(90deg); */
}
.cntTop{
  scroll-behavior: smooth;
}

.scrollContainer:before {
  content: '';
  display: block;
  height: 100vh;
  pointer-events: none;
}

.scrollContainer a {
  position: sticky;
  top: 88vh;
  cursor: pointer;
  font-size: 32px;
}
.scrollBtn{
  color: #052925;
}


::-webkit-scrollbar {
  display: none;
}
*{
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}
@font-face {
  font-family: "meidinger";
  src: url("./utils/fonts/meidinger/Miedinger_-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "hamlin-light";
  src: url("./utils/fonts/hamlin/Hamlin-Light.ttf") format("truetype");
  font-weight: light;
  font-style: normal;
}
@font-face {
  font-family: "hamlin-regular";
  src: url("./utils/fonts/hamlin/Hamlin-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "hamlin-bold";
  src: url("./utils/fonts/hamlin/Hamlin-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "hamlin-bolder";
  src: url("./utils/fonts/hamlin/Hamlin-ExtraBold.ttf") format("truetype");
  font-weight: bolder;
  font-style: normal;
}

p::selection{

   color: white;
    background-color: #052925;

}
h1::selection{

   color: white;
    background-color: #052925;

}
h2::selection{

   color: white;
    background-color: #052925;

}
h3::selection{

   color: white;
    background-color: #052925;

}
h4::selection{

   color: white;
    background-color: #052925;

}
h5::selection{

   color: white;
    background-color: #052925;

}
a::selection{

   color: white;
    background-color: #052925;

}
*::selection{

   color: white;
    background-color: #052925;

}
