 .navbarMain{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100vw;
    top: 0;
    background-color: #e9e8e4;
    background:none ;
  z-index: 997;
  
 }
 .opaqueBg{
  /* background-color: #eeeada; */
  background: rgba( 255, 255, 255, 0.1 );
  /* box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ); */
  backdrop-filter: blur( 6px );
  -webkit-backdrop-filter: blur( 6px );
  /* border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );   */
 }
 /* .opaqueBg{
  background-color: #e8e8e8;
background-image: url("data:image/svg+xml,%3Csvg width='100' height='20' viewBox='0 0 100 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z' fill='%23052925' fill-opacity='0.02' fill-rule='evenodd'/%3E%3C/svg%3E");
 } */


.navLogo{
    margin-left: 10px;
}
  .nav{
    position: absolute;
    width: 700px;
    top: 0;
    right: 0;
    background-color: #052925;
    height: 100vh;
    
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23ffffff' fill-opacity='0.26' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
  }
  .nav-slide-right {

    transition: all 1.2s ease-in-out;
    transform: translateX(0vw);
  }
  
  .nav-slide-left {
    transition: all 0.6s ease-in-out;
    transform: translateX(-170vw);
  }
  .nav1-slide-right {

    transition: all 0.6s ease-in-out;
    transform: translateX(0vw);
    background-color: #042723c6;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    background: #042723c6;
/* box-shadow: 0 8px 32px 0 #042723c6; */
backdrop-filter: blur( 10px );
-webkit-backdrop-filter: blur( 10px );
border: 1px solid rgba( 255, 255, 255, 0.18 );

  }
  
  .nav1-slide-left {
    transition: all 1.6s ease-in-out;
    transform: translateX(-500vw);
    background-color: #042723c6;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    background: #042723c6;
/* box-shadow: 0 8px 32px 0 #042723c6; */
backdrop-filter: blur( 10px );
-webkit-backdrop-filter: blur( 10px );
border: 1px solid rgba( 255, 255, 255, 0.18 );
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23ffffff' fill-opacity='0.26' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");

  }
  .nav2-slide-right {

    transition: all 1.2s ease-in-out;
    transform: translateY(0vw);
    background-color: #052925c7;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    background: #052925c7;
/* box-shadow: 0 8px 32px 0 #052925c7; */
backdrop-filter: blur( 10px );
-webkit-backdrop-filter: blur( 10px );
border: 1px solid rgba( 255, 255, 255, 0.18 );
background-image: url("data:image/svg+xml,%3Csvg width='80' height='88' viewBox='0 0 80 88' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 21.91V26h-2c-9.94 0-18 8.06-18 18 0 9.943 8.058 18 18 18h2v4.09c8.012.722 14.785 5.738 18 12.73 3.212-6.99 9.983-12.008 18-12.73V62h2c9.94 0 18-8.06 18-18 0-9.943-8.058-18-18-18h-2v-4.09c-8.012-.722-14.785-5.738-18-12.73-3.212 6.99-9.983 12.008-18 12.73zM54 58v4.696c-5.574 1.316-10.455 4.428-14 8.69-3.545-4.262-8.426-7.374-14-8.69V58h-5.993C12.27 58 6 51.734 6 44c0-7.732 6.275-14 14.007-14H26v-4.696c5.574-1.316 10.455-4.428 14-8.69 3.545 4.262 8.426 7.374 14 8.69V30h5.993C67.73 30 74 36.266 74 44c0 7.732-6.275 14-14.007 14H54zM42 88c0-9.94 8.06-18 18-18h2v-4.09c8.016-.722 14.787-5.738 18-12.73v7.434c-3.545 4.262-8.426 7.374-14 8.69V74h-5.993C52.275 74 46 80.268 46 88h-4zm-4 0c0-9.943-8.058-18-18-18h-2v-4.09c-8.012-.722-14.785-5.738-18-12.73v7.434c3.545 4.262 8.426 7.374 14 8.69V74h5.993C27.73 74 34 80.266 34 88h4zm4-88c0 9.943 8.058 18 18 18h2v4.09c8.012.722 14.785 5.738 18 12.73v-7.434c-3.545-4.262-8.426-7.374-14-8.69V14h-5.993C52.27 14 46 7.734 46 0h-4zM0 34.82c3.213-6.992 9.984-12.008 18-12.73V18h2c9.94 0 18-8.06 18-18h-4c0 7.732-6.275 14-14.007 14H14v4.696c-5.574 1.316-10.455 4.428-14 8.69v7.433z' fill='%23ffffff' fill-opacity='0.07' fill-rule='evenodd'/%3E%3C/svg%3E");
  }
  
  .nav2-slide-left {
    transition: all 1s ease-in-out;
    transform: translateY(-500vw);
    background-color: #052925c7;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    background: #052925c7;
/* box-shadow: 0 8px 32px 0 #052925c7; */
backdrop-filter: blur( 10px );
-webkit-backdrop-filter: blur( 10px );
border: 1px solid rgba( 255, 255, 255, 0.18 );
background-image: url("data:image/svg+xml,%3Csvg width='80' height='88' viewBox='0 0 80 88' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 21.91V26h-2c-9.94 0-18 8.06-18 18 0 9.943 8.058 18 18 18h2v4.09c8.012.722 14.785 5.738 18 12.73 3.212-6.99 9.983-12.008 18-12.73V62h2c9.94 0 18-8.06 18-18 0-9.943-8.058-18-18-18h-2v-4.09c-8.012-.722-14.785-5.738-18-12.73-3.212 6.99-9.983 12.008-18 12.73zM54 58v4.696c-5.574 1.316-10.455 4.428-14 8.69-3.545-4.262-8.426-7.374-14-8.69V58h-5.993C12.27 58 6 51.734 6 44c0-7.732 6.275-14 14.007-14H26v-4.696c5.574-1.316 10.455-4.428 14-8.69 3.545 4.262 8.426 7.374 14 8.69V30h5.993C67.73 30 74 36.266 74 44c0 7.732-6.275 14-14.007 14H54zM42 88c0-9.94 8.06-18 18-18h2v-4.09c8.016-.722 14.787-5.738 18-12.73v7.434c-3.545 4.262-8.426 7.374-14 8.69V74h-5.993C52.275 74 46 80.268 46 88h-4zm-4 0c0-9.943-8.058-18-18-18h-2v-4.09c-8.012-.722-14.785-5.738-18-12.73v7.434c3.545 4.262 8.426 7.374 14 8.69V74h5.993C27.73 74 34 80.266 34 88h4zm4-88c0 9.943 8.058 18 18 18h2v4.09c8.012.722 14.785 5.738 18 12.73v-7.434c-3.545-4.262-8.426-7.374-14-8.69V14h-5.993C52.27 14 46 7.734 46 0h-4zM0 34.82c3.213-6.992 9.984-12.008 18-12.73V18h2c9.94 0 18-8.06 18-18h-4c0 7.732-6.275 14-14.007 14H14v4.696c-5.574 1.316-10.455 4.428-14 8.69v7.433z' fill='%23ffffff' fill-opacity='0.07' fill-rule='evenodd'/%3E%3C/svg%3E");

  }
  .navbar{
    color: #edb970;
    margin-top: 150px;

  }
  .navbar>ul>li{
    list-style: none;
    font-size: 40px;
    font-family: hamlin-bold;
    margin-top: 20px;
    margin-right: 40px;
    text-align: right;
    z-index: 999;
    
    
  }
  
  
.nav-link{
    text-decoration: none;
    color: #edb970;

}
 
.hambur{
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-right: 20px;
}
  #hamburger {
   position: relative;

    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    /* width: 50px; */
    padding: 20px;
    right: 0;
    
  justify-content: end;
  /* border: 2px solid red; */
  z-index: 999;

  }
  #hamburger .line {
    fill: none;
    stroke: #edb970;
    stroke-width: 8;
    box-shadow: 5px 5px 10px #000;
    transition: stroke-dasharray 1000ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 1000ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  #hamburger .line1 {
    stroke-dasharray: 60 207;
    stroke-width: 8;
    box-shadow: 5px 5px 10px #000;

  }
  #hamburger .line2 {
    stroke-dasharray: 60 60;
    stroke-width: 8;
    box-shadow: 5px 5px 10px #000;

  }
  #hamburger .line3 {
    stroke-dasharray: 60 207;
    stroke-width: 8;
    box-shadow: 5px 5px 10px #000;

  }
  #hamburger.open .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 8;
    stroke: #edb970;
 

  }
  #hamburger.open .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 8;

    stroke: #edb970;

  }
  #hamburger.open .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 8;
    stroke: #edb970;
   

  }