
.fieldMain{
  /* padding-top: 100px; */

  position: relative;

}


.fieldImgDiv{
   min-width: 40vw;
    position: relative;
    /* border: 2px solid red; */

}
.fieldImg{
    position: inherit;
    z-index: -1;
    width: 500px;
    height: 715px;
    /* aspect-ratio: 9/16; */
    /* animation: slideInLeft 1s linear 1.8s 1 ; */

}

.fieldOverlay1{
    position: absolute;
    background-color: #052925;
    opacity: 0.9;
z-index: -2;
    width: 100%;
    height: 715px;
    background-image: url("data:image/svg+xml,%3Csvg width='100' height='20' viewBox='0 0 100 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z' fill='%23eff6f5' fill-opacity='0.16' fill-rule='evenodd'/%3E%3C/svg%3E");
    /* transform: translateX(0); */
    /* animation: slideInLeft 3s linear 1s 1 ; */
}



.fieldOverlay2{
    position: absolute;
    background-color: #edb970;
    width: 105%;
    height: 415px;
    top: 300px;
    z-index: -1;
    /* animation: slideInLeft 2s linear 1.4s 1 ; */
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='12' viewBox='0 0 20 12'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='charlie-brown' fill='%23052925' fill-opacity='0.14'%3E%3Cpath d='M9.8 12L0 2.2V.8l10 10 10-10v1.4L10.2 12h-.4zm-4 0L0 6.2V4.8L7.2 12H5.8zm8.4 0L20 6.2V4.8L12.8 12h1.4zM9.8 0l.2.2.2-.2h-.4zm-4 0L10 4.2 14.2 0h-1.4L10 2.8 7.2 0H5.8z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

}
/* @keyframes slideInLeft {
    0% {
      transform: translateX(-100%);
    }
    50%{
        transform: translateX((30%));
    }
    70%{
        transform: translateX(-90%);
    }
    90%{
        transform: translateX(10%);

    }
    100% {
      transform: translateX(0);
    }
  } */




.fieldDiv1{
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.fieldHeroHead{
    display: none;
}

.fieldListDiv{
    width: 60vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
   padding: 20px 0;
   /* border: 2px solid red; */

}
.fieldList{
    border-left: 5px solid #052925;
    background-color: #f9f8f5;
    width: 350px;
    height: 100px;
   display: flex;
   flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px ;
    text-align: left;
    margin: 20px;
    /* position: relative; */
    /* z-index: -2; */

}











  .the-arrow {
    width: 64px;
    transition: all 0.2s;
  }
  .the-arrow.-left {
    position: absolute;
    top: 60%;
    left: 0;
  }
  .the-arrow.-left > .shaft {
    width: 0;
    background-color: #052925;
  }
  .the-arrow.-left > .shaft:before, .the-arrow.-left > .shaft:after {
    width: 0;
    background-color: #052925;
  }
  .the-arrow.-left > .shaft:before {
    transform: rotate(0);
  }
  .the-arrow.-left > .shaft:after {
    transform: rotate(0);
  }
  .the-arrow.-right {
    top: 3px;
  }
  .the-arrow.-right > .shaft {
    width: 64px;
    transition-delay: 0.2s;
  }
  .the-arrow.-right > .shaft:before, .the-arrow.-right > .shaft:after {
    width: 8px;
    transition-delay: 0.3s;
    transition: all 0.5s;
  }
  .the-arrow.-right > .shaft:before {
    transform: rotate(40deg);
  }
  .the-arrow.-right > .shaft:after {
    transform: rotate(-40deg);
  }
  .the-arrow > .shaft {
    background-color: #052925;
    display: block;
    height: 1px;
    position: relative;
    transition: all 0.2s;
    transition-delay: 0;
    will-change: transform;
  }
  .the-arrow > .shaft:before, .the-arrow > .shaft:after {
    background-color: #052925;
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    top: 0;
    right: 0;
    transition: all 0.2s;
    transition-delay: 0;
  }
  .the-arrow > .shaft:before {
    transform-origin: top right;
  }
  .the-arrow > .shaft:after {
    transform-origin: bottom right;
  }
  
  .animated-arrow {
    display: inline-block;
    color: #052925;
    font-size: 1.25em;
    font-style: italic;
    text-decoration: none;
    position: relative;
    transition: all 0.2s;
    width: 100%;
    /* z-index: -1; */
    /* height: 100%; */
  }
  .animated-arrow:hover {
    color: #052925;
  }
  .animated-arrow:hover > .the-arrow.-left > .shaft {
    width: 64px;
    transition-delay: 0.1s;
    background-color: #052925;
  }
  .animated-arrow:hover > .the-arrow.-left > .shaft:before, .animated-arrow:hover > .the-arrow.-left > .shaft:after {
    width: 8px;
    transition-delay: 0.1s;
    background-color: #052925;
  }
  .animated-arrow:hover > .the-arrow.-left > .shaft:before {
    transform: rotate(40deg);
  }
  .animated-arrow:hover > .the-arrow.-left > .shaft:after {
    transform: rotate(-40deg);
  }
  .animated-arrow:hover > .main {
    transform: translateX(80px);
  }
  .animated-arrow:hover > .main > .the-arrow.-right > .shaft {
    width: 0;
    transform: translateX(200%);
    transition-delay: 0;
  }
  .animated-arrow:hover > .main > .the-arrow.-right > .shaft:before, .animated-arrow:hover > .main > .the-arrow.-right > .shaft:after {
    width: 0;
    transition-delay: 0;
    transition: all 0.1s;
  }
  .animated-arrow:hover > .main > .the-arrow.-right > .shaft:before {
    transform: rotate(0);
  }
  .animated-arrow:hover > .main > .the-arrow.-right > .shaft:after {
    transform: rotate(0);
  }
  .animated-arrow > .main {
    display: flex;
    align-items: center;
    transition: all 0.2s;
  }
  .animated-arrow > .main > .text {
    margin: 0 16px 0 0;
    line-height: 1;
  }
  .animated-arrow > .main > .the-arrow {
    position: relative;
  }






  .fieldSection{
    scroll-behavior: smooth;
    scroll-margin-top:100px ;
    max-width:800px ;
    margin: 100px auto;
    padding: 0 10px;
  }
  .fieldHead{
    text-align: left;
    font-family: meidinger;
    margin: 20px 0;
    font-size: larger;
    letter-spacing: 0.7px;
    padding-bottom: 10px;
    border-bottom: 3px solid #052925;
  }
  .fieldPara{
    font-size: 16px;
    font-family: hamlin-light;
    text-align: justify;
    margin-top: 10px;

  }





  .btn-darrow {
    background: none;
    border: none;
    outline: none;
  }
  .btn-darrow svg {
    transition: all 0.3s;
  }
  .btn-darrow svg:nth-child(1) {
    opacity: 0.63;
  }
  .btn-darrow svg:nth-child(2) {
    margin-left: -9px;
  }
  .btn-darrow.btn-animate svg:nth-child(1) {
    animation: toogleOpacity 1.5s ease infinite;
    will-change: opacity;
  }
  .btn-darrow.btn-animate svg:nth-child(2) {
    will-change: transform;
    animation: floating 1.5s ease infinite;
  }
  
  @keyframes floating {
    0% {
      transform: translateX(0px);
    }
    50% {
      transform: translateX(-17.876px);
    }
    100% {
      transform: translateX(0px);
    }
  }
  @keyframes toogleOpacity {
    0% {
      opacity: 0.63;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0.63;
    }
  }


  @media screen  and (max-width:820px) and (min-width:486px){




    .fieldImgDiv{
        min-width: 100vw;
        height: 50vh;
        overflow: hidden;
        object-fit: cover;
        object-position: center;

        position: relative;
        /* border: 2px solid red; */

    }
    .fieldImg{
        position: inherit;
        z-index: -1;
        width: 500px;
        height: 715px;
        /* aspect-ratio: 9/16; */
        /* animation: slideInLeft 1s linear 1.8s 1 ; */
    
    }
    
    .fieldOverlay1{
        position: absolute;
        background-color: #052925;
        opacity: 0.9;
    z-index: -2;
        width: 100%;
        height: 715px;
        /* transform: translateX(0); */
        /* animation: slideInLeft 3s linear 1s 1 ; */
    }
    .fieldHeroHead{
        position: absolute;
        top: 70px;
        right: 0px;
        color: #f9f8f5;
        font-size: 32px;
        font-family: hamlin-light;
        text-transform:uppercase;
        max-width:250px ;
        word-wrap: break-word;
   display: block;

    }
    
    
    .fieldOverlay2{
        position: absolute;
        background-color: #edb970;
        width: 90%;
        height: 415px;
        top: 300px;
        z-index: -1;

        /* animation: slideInLeft 2s linear 1.4s 1 ; */
    
    
    }
  
    
    
    
 
    .fieldDiv1{
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
       
    }
    
    
    
    .fieldListDiv{
        width: 100vw;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 50px;
       padding: 20px 0;
       /* border: 2px solid red; */
    
    }
    .fieldList{
        border-left: 5px solid #052925;
        background-color: #f9f8f5;
        width: 300px;
        height: 100px;
       display: flex;
       flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 20px ;
        text-align: left;
        margin: 20px;
       
    
    }
    
    
    
    
    
  }


  @media screen and (max-width:486px) {
    .fieldImgDiv{
      display: none;
    }
    .fieldListDiv{
      width: 100vw;
      margin-top: 100px;
    }
    .fieldSection{
      scroll-behavior: smooth;
      scroll-margin-top:100px ;
      max-width:800px ;
      margin: 100px auto;
      padding: 0 20px;
    }
   
  }