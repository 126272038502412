.homeMain {
  /* height: 100vh; */
  /* padding-top: 50px; */
  /* overflow: scroll; */
  /* scroll-snap-type: y mandatory; */
  /* scroll-snap-stop: always; */
  /* border: 1px solid red; */
  position: relative;
}

.ws-footer{
  text-decoration: none;
  color: rgb(0, 220, 0);
}

/* Modal style */
.modalMain{

  position: sticky;
  height: 100vh;
  width: 100vw;
  background-color: #031715;
  top: 0;
  left: 0;
  z-index: 998;
/* background-color: #DFDBE5; */
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='12' viewBox='0 0 20 12'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='charlie-brown' fill='%23fffffd' fill-opacity='0.08'%3E%3Cpath d='M9.8 12L0 2.2V.8l10 10 10-10v1.4L10.2 12h-.4zm-4 0L0 6.2V4.8L7.2 12H5.8zm8.4 0L20 6.2V4.8L12.8 12h1.4zM9.8 0l.2.2.2-.2h-.4zm-4 0L10 4.2 14.2 0h-1.4L10 2.8 7.2 0H5.8z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.modalDisplay{
    display: block;

}
.modalHidden{
    display: none;

}

.modalContent {
  position: absolute;
 overflow: scroll;
  z-index: 999;
  background-color:#fffffffd;
  width: 1000px;
  margin: auto;

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  scroll-behavior: smooth;

  padding: 30px 50px;
  text-align: justify;
  background-image: url("data:image/svg+xml,%3Csvg width='48' height='32' viewBox='0 0 48 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23052925' fill-opacity='0.09'%3E%3Cpath d='M27 32c0-3.314 2.686-6 6-6 5.523 0 10-4.477 10-10S38.523 6 33 6c-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 6.627 0 12 5.373 12 12s-5.373 12-12 12c-2.21 0-4 1.79-4 4h-2zm-6 0c0-3.314-2.686-6-6-6-5.523 0-10-4.477-10-10S9.477 6 15 6c3.314 0 6-2.686 6-6h-2c0 2.21-1.79 4-4 4C8.373 4 3 9.373 3 16s5.373 12 12 12c2.21 0 4 1.79 4 4h2z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.modalContent > h2 {
  font-family: hamlin-bolder;
  font-size: 30px;
  padding: 5px 0;

  border-bottom: 3px solid #052925c7;
}

.modalContent > p {
  margin-top: 20px;
}

.modalContent > div {
  padding: 20px;
}

.modalBtn {
  margin-top: 20px;
  padding: 7px 15px;
  font-size: 18px;
  font-weight: 600;
  background-color: #052925;
  color: white;
  border: none;
  outline: none;
}
.modalBtn:hover {
  margin-top: 20px;
  padding: 7px 15px;
  font-size: 18px;
  font-weight: 600;
  background-color: #04201d;
  color: white;
  border: none;
  outline: none;
}

.homeContent1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.homeContent1Div1 {
  max-width: 40vw;
  /* border: 1px solid red; */
  padding: 20px;
  margin: 40px;
  /* border: 3px solid #052925c7; */
  background-color: #ffffff7a;
  box-shadow: rgba(100, 100, 111, 0.3) 0px 4px 20px 0px;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='20' viewBox='0 0 100 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z' fill='%23052925' fill-opacity='0.05' fill-rule='evenodd'/%3E%3C/svg%3E");
}
.homeContent1Div1 > img {
  width: 80px;
  height: 80px;
  border: 3px solid #052925c7;
  padding: 10px;
  border-radius: 500px;
}

.homeContent1Div1 > h3 {
  font-family: meidinger;
  font-weight: 800;
  font-size: 24px;
  margin-top: 10px;
  letter-spacing: 1px;
}

.homeContent1Div1 > p {
  font-family: hamlin-light;
  font-size: 16px;
  margin-top: 20px;
  text-align: justify;
  line-height: 22px;
}

.homeContent1Div2 {
  max-width: 40vw;
  /* border: 1px solid red; */
  padding: 20px;
  margin: 40px;
  /* border: 3px solid #052925c7; */
  background-color: #ffffff7a;
  box-shadow: rgba(100, 100, 111, 0.3) 0px 4px 20px 0px;
  /* background-color: #DFDBE5; */
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='20' viewBox='0 0 100 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z' fill='%23052925' fill-opacity='0.05' fill-rule='evenodd'/%3E%3C/svg%3E");
}
.homeContent1Div2 > img {
  width: 80px;
  height: 80px;
  border: 3px solid #052925c7;
  padding: 10px;
  border-radius: 500px;
}

.homeContent1Div2 > h3 {
  font-family: meidinger;
  font-weight: 800;
  font-size: 24px;
  margin-top: 10px;
  letter-spacing: 1px;
}

.homeContent1Div2 > p {
  font-family: hamlin-light;
  font-size: 16px;
  margin-top: 20px;
  line-height: 22px;
  text-align: justify;
}

.homeContent2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 50px;
  margin-top: 20px;
}

.homeContent2Div1 {
  width: 40vw;
  height: fit-content;
}

.homeAboutImg {
  width: 30vw;
}

.homeContent2Div2 {
  width: 60vw;
  height: fit-content;
}
.homeContent2Div2 > h2 {
  font-size: 32px;
  font-family: meidinger;
  margin: 10px 0;
}
.homeContent2Div2 > p {
  font-family: hamlin-light;
  font-size: 18px;
  padding-right: 50px;
  text-align: justify;
  margin-bottom: 40px;
}

.knowMoreBtn {
  background-color: #052925;
  text-decoration: none;
  color: white;
  font-family: meidinger;
  padding: 5px 10px;
  font-size: 20px;
  border-radius: 5px;
  margin-top: 40px;
}
.knowMoreBtn:hover {
  background-color: #031715;
  /* border:2px solid #052925c7; */

  transition: all 0.2s ease-in-out;
}

.serviceContent {
  margin-top: 50px;
  width: 100vw;
  padding: 20px;
  background-color: #e0dada50;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='20' viewBox='0 0 100 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z' fill='%23052925' fill-opacity='0.07' fill-rule='evenodd'/%3E%3C/svg%3E");
}

.serviceContent > p {
  max-width: 1000px;
  margin: auto;
  font-size: 16px;

  text-align: center;
  margin-top: 20px;
}

.serviceHead {
  text-align: center;
  font-family: meidinger;
  padding-top: 20px;
  font-size: 40px;
}

.serviceContainer {
  width: 80vw;
  margin: 20px auto;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.serviceCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 50px;
  min-width: 300px;
  height: 150px;
  border: 3px solid #052925c7;
}

.serviceIcon {
  font-size: 64px;
  margin: 20px 0;
  color: #052925c7;
}

.serviceCard > h3 {
  font-size: 24px;
}
.exploreBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-family: hamlin-bolder;
  font-size: 20px;
  font-weight: 800;
  background-color: #052925c7;
  color: white;
  width: 170px;
  margin: auto;
  height: 40px;
  border-radius: 3px;
  /* border:2px solid #052925c7; */
}
.exploreBtn:hover {
  background-color: #052925e6;
  /* border:2px solid #052925c7; */

  transition: all 0.2s ease-in-out;
}



.infoContent{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:center;
    align-items: center;
    margin-top: 50px;
    /* border: 1px solid red; */
}
.infoHead{
    font-family: poppins;
    letter-spacing: 1px;
    text-align: center;
    font-size: 40px;
    border-bottom: 5px solid #052925;

    width: fit-content;
    margin: auto;
margin-top: 30px;

}

.infoCard{
    border: 3px solid #052925;
    padding: 30px;
    margin: 10px auto;
    width: 330px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


}
.infoCard>p{
    text-align: center;
    font-size: 35px;
    font-family: Montserrat;
    font-weight: 700;
  letter-spacing: 1px;

}

.infoCard>h3{
    text-align: center;
    font-size: 20px;
    font-family: meidinger;

}





.chooseUsContent {
  background: url("../../utils/images/law.webp");
  width: 100vw;
  height: 800px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  margin-top: 130px;
  z-index: 0;
  padding-bottom: 20px;
  /* border: 1px solid red; */
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #052925c7;
  z-index: -1;
}
.chooseHead {
  color: white;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 10px;

  font-size: 52px;
  font-family: meidinger;
  letter-spacing: 2px;
  border-bottom: 3px solid #edb970;
  width: fit-content;
  margin: auto;
}

.chooseContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: auto;
  max-width: 1300px;
}

.chooseCard {
  width: 400px;
  margin: 10px;

  height: 250px;
  border: 3px solid white;
}

.chooseCard > h3 {
  text-align: center;
  font-size: 24px;
  font-family: hamlin-bolder;
  color: #edb970;
  padding: 20px 0 10px 0;
}

.chooseCard > p {
  padding: 10px;
  text-align: justify;
  word-wrap: break-word;
  font-size: 16px;
  font-family: hamlin-light;
  color: white;
}

@media screen and (max-width: 830px) and (min-width: 487px) {
    .modalContent{
        max-width: 750px;
    }
  .homeContent1Div1 {
    margin: 10px auto;
    max-width: 45vw;
  }
  .homeContent1Div2 {
    margin: 10px auto;
    max-width: 45vw;
  }

  .homeContent2Div1 {
    display: none;
  }
  .homeContent2Div2 {
    width: 100vw;
    height: fit-content;
  }
  .homeContent2Div2 > h2 {
    font-size: 32px;
    font-family: meidinger;
    margin: 10px 0;
  }
  .homeContent2Div2 > p {
    font-family: hamlin-light;
    font-size: 18px;
    padding-right: 0px;
    text-align: justify;
    margin-bottom: 40px;
  }
  .serviceContainer {
    width: 100vw;
    flex-wrap: wrap;
  }

  .serviceCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin: 10px;
    max-width: 80px;
    height: 150px;
    border: 3px solid #052925c7;
  }

  .serviceIcon {
    font-size: 48px;
    margin: 20px 0;
    color: #052925c7;
  }

  .serviceCard > h3 {
    font-size: 20px;
  }
  .chooseUsContent {
    height: fit-content;
  }

  .chooseContainer {
    width: 100vw;
    /* border: 1px solid red; */
    margin: 40px 0;
    padding: 0;
  }
  .chooseCard {
    width: 350px;
    padding: 10px;
    margin: 10px;

    height: 300px;
  }

  .chooseCard > p {
    font-size: 16px;
  }
}

@media screen and (max-width: 486px) {
    .modalContent{
        max-width: 90vw;
        /* top: 0;
        left: 0; */
        max-height:100vh ;
        /* overflow-y: scroll; */
        margin: auto ;
        box-sizing: border-box;
        margin-top: 20px;
        padding: 30px 20px;
        /* transform: translate(0%,0%); */
        border: 3px solid #052925;
    }
    .moadalMain{
        background-color: #052925;
        z-index: 998;
  height: fit-content;
        
        position: absolute;

    }
    .modalContent > h2 {
        font-family: hamlin-bolder;
        font-size: 24px;
        padding: 5px 0;
      
        border-bottom: 3px solid #052925c7;
      }
      
      .modalContent > p {
        margin-top: 18px;
      }
      
      .modalContent > div {
        padding: 10px;
      }
  .homeContent1 {
    flex-direction: column;
  }
  .homeContent1Div1 {
    max-width: 100%;
    margin: 15px;
  }
  .homeContent1Div2 {
    max-width: 100%;
    margin: 15px;
  }
  .homeContent2 {
    padding: 20px;
  }
  .homeContent2Div1 {
    display: none;
  }
  .homeContent2Div2 {
    width: 100%;
    /* border: 1px solid red; */
  }
  .serviceContent {
    padding: 20px 0;
  }
  .homeContent2Div2 > p {
    font-family: hamlin-light;
    font-size: 18px;
    padding: 0px;
    text-align: justify;
    margin-bottom: 40px;
  }

  .serviceContent > p {
    max-width: 600px;
    margin: auto;
    font-size: 16px;
    padding: 0 10px;
    text-align: center;
    margin-top: 20px;
  }

  .serviceHead {
    text-align: center;
    font-family: meidinger;
    padding: 20px 0;
    font-size: 35px;
    max-width: 300px;
    margin: auto;

    border-bottom: 3px solid #052925c7;
  }

  .serviceContainer {
    max-width: 100vw;
    padding: 0;
    flex-direction: column;
    margin: 20px auto;
    /* border: 1px solid red; */
  }

  .serviceCard {
    max-width: 300px;
    margin: 10px auto;
  }
  .chooseUsContent {
    height: fit-content;
  }
}
